import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Subject, takeUntil } from 'rxjs';
import { GtmService } from './gtm.service';
import { MarketService } from './market.service';
import { UserService } from './user/user.service';

@Injectable()
export class MetaService implements OnDestroy {
	private renderer: Renderer2;

	private siteVerificationMap = {
		SE: 'L9c5Ncwjf8jx9Ds2aYzXzHE_by5RoLfJb5Lg2p_2X1E',
		FI: 'FEkbqzdmdKpotPbWQ14WpnemtO8Nj-aJZCtV4wvmoDg',
		EE: '9wA9swD5PvJzrOXHD5Qj4TEwyznHSyD8wdIrtvMcmNI',
		NO: '7XhwPyjIJm8L1uzKXdtErb3aZiO47fUsA0BY60cNVOo',
		LT: 'Qv8eWK61cF2aRJXJ1i_ATpas_8GUxNJslstO1D9gbHk',
		LV: '4LJkiGgm8-_wIkMLgJ6U1AnQqktAWpzNlC91Hul36oY',
	};

	private isLoggedIn = false;
	private onDestroy$ = new Subject();

	constructor(
		private title: Title,
		private metadata: Meta,
		private gtmService: GtmService,
		private rendererFactory: RendererFactory2,
		private marketService: MarketService,
		private userService: UserService,
		@Inject(DOCUMENT) private document: Document,
	) {
		this.renderer = this.rendererFactory.createRenderer(null, null);
		this.userService.isLoggedIn$.pipe(takeUntil(this.onDestroy$)).subscribe((isAuthenticated) => {
			this.isLoggedIn = isAuthenticated;
		});
	}

	public setMeta(title: string, metaList: MetaData[] = []) {
		this.setHtmlLang(this.marketService.currentLanguage);

		this.title.setTitle(title);

		this.metadata.updateTag({ property: 'og:title', content: title });
		this.metadata.updateTag({ property: 'og:url', content: window.location.href });
		this.metadata.updateTag({ property: 'og:type', content: 'website' });
		this.metadata.updateTag({ name: 'prerender-status-code', content: '200' });

		metaList.forEach((meta) => {
			switch (meta.key) {
				case 'og:image':
					this.metadata.updateTag({ property: meta.key, content: meta.value });
					break;
				case 'og:description':
					this.metadata.updateTag({ property: meta.key, content: meta.value });
					break;
				default:
					this.metadata.updateTag({ name: meta.key, content: meta.value });
					break;
			}
		});

		this.gtmService.setPageNavigation(this.isLoggedIn);
	}

	public setNoIndex() {
		this.metadata.addTag({ name: 'robots', content: 'noindex' });
	}

	public addCanonical() {
		const link: HTMLLinkElement = this.document.createElement('link');
		let hrefTarget: string = this.isRoot()
			? `${this.document.URL}${this.marketService.currentLanguage}`
			: this.document.URL;
		hrefTarget = hrefTarget.toLowerCase();
		link.setAttribute('rel', 'canonical');
		link.setAttribute('href', hrefTarget);
		this.document.head.appendChild(link);
	}

	public addSiteVerification() {
		const market = this.marketService.currentMarket;
		this.metadata.addTag({ name: 'google-site-verification', content: this.siteVerificationMap[market] });
	}

	public removeCanonical() {
		const canonical = this.document.querySelector('link[rel="canonical"]');
		if (canonical) {
			this.document.head.removeChild(canonical);
		}
	}

	private isRoot() {
		const path = window.location.pathname;
		return path === '/' || path === '' || path === '/index.html';
	}

	private setHtmlLang(lang: string) {
		this.renderer.setAttribute(document.querySelector('html'), 'lang', lang);
	}

	ngOnDestroy() {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}
}

export interface MetaData {
	key: string;
	value: string;
}
